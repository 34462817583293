<template>
  <el-card>
    <div slot="header">
      <h3>意见反馈</h3>
    </div>

    <el-form ref="form" :rules="rules" :model="form" label-width="100px" label-suffix="：">

      <el-form-item label="意见反馈" prop="memo">
        <el-input type="textarea" v-model="form.memo" :rows="10"></el-input>
      </el-form-item>

      <el-form-item label="您的电话" prop="telphone">
        <el-input v-model="form.telphone"></el-input>
      </el-form-item>
      <el-form-item label="您的邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="您的姓名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
     <!-- <el-form-item label="验证码" prop="captcha">
        <el-row>
          <el-col :span="9">
            <el-input v-model="form.captcha"></el-input>
          </el-col>
          <el-col :span="6">
            <el-image :src="captcha" style="width: 100px; height: 35px; margin-left: 10px"
                      @click="getCaptcha()"></el-image>
          </el-col>
        </el-row>
      </el-form-item> -->

      <el-form-item class="text-center">
        <el-button @click="submit2">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
  import axios from "axios"; 
  import {getCaptha} from "../../../api/LoginApi";
  import {saveSuggestion} from "../../../api/CommonApi";

  export default {
    name: "FeedbackIndex",
    data() {
      return {
        form: {},
        captcha: getCaptha(),

        rules: {
          telphone: [
            {required: true, message: "电话不能为空", trigger: "blur"}
          ],
          name: [
            {required: true, message: "姓名不能为空", trigger: "blur"}
          ],
          memo: [
            {required: true, message: "内容不能为空", trigger: "blur"}
          ],
          captcha: [
            {required: false, message: "验证码不能为空", trigger: "blur"}
          ]
        }
      }
    },

    methods: {

      /**
       * 获取验证码
       */
      getCaptcha() {
        this.captcha = getCaptha() + "?" + Math.random();
      },
    submit2() {
        this.$refs['form'].validate((valid) => {
          if (valid){
			
				 const params = new URLSearchParams(); 
				 params.append('uid', sessionStorage.getItem('uid'));
				 params.append('bid', sessionStorage.getItem('uid'));
				 params.append('content', this.form.memo); 
				 params.append('tel', this.form.telphone); 
				 params.append('email', this.form.email); 
				 params.append('name', this.form.name); 
				 
				 axios.post(this.apiUrl+'/api/user/setOpinion',params)
				 .then((response) => {  
				 	console.log(response.data.data);
				 	this.info = response.data.data; 
					this.$message.success("提交成功");
				 			  
				 }) 
				 .catch(function(error) {
				   console.log(error);
				 });
          }
        })
      },
      submit() {
        this.$refs['form'].validate((valid) => {
          if (valid){
            saveSuggestion(this.form).then(() => {
              this.$message.success("提交成功");
              this.form = {}
            })
          }
        })
      },
	  
    }
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        padding: 0;
        margin: 0;
      }
    }

    .el-form {
      padding: 10px 40px;

      .el-input {
        width: 300px;
      }

      .el-textarea {
        width: 600px;
      }

      .el-button {
        margin-left: 36%;
        width: 120px;
      }
    }
  }
</style>
